import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404" />
    <Layout>
      <h1>:(</h1>
      <h3>A página que você está procurando não está aqui!</h3>
      <h5>Ou ela nunca existiu ou alguém roubou ela.</h5>
    </Layout>
  </>
)

export default NotFoundPage
